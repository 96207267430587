.home-container>h3 {
    margin-top: 45px;
}


.block-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:16px;
}

.title{
font-weight:600;
}

.mt0{
    margin-top:0;
}

.token-infor{
    flex:1;
    display: flex;
    align-items: center;

    padding: 5px 10%;
    
    font-weight: 500;
}

._wrap > .rs-panel-body{
    height: 356px;

    display: grid;
    grid-template-columns: repeat(2,1fr);

    padding:0 !important;

    font-weight:500;
}

.left{
    width: 65px;
}

.fw700{
    font-weight: 700;
}


.wrap-token{
    background: var(--panel-bg-color);
    padding: 0;
    margin-bottom:30px;
}
.wrap-token ._wrap {
    height: 100%;
}

.home-top-section {
    flex-flow: row;
    align-self: unset;
}

.bocktime-chart-container {
    padding: 20px;
    background: 'white';
}

@media screen and (min-width: 1300px){
    .section-left, .section-right, .wrap-token {
        width: calc(50% - 5px);
    }

    .section-left {
        margin-right: 5px;
    }

    .section-right {
        margin-left: 5px;
    }
}


@media screen and (max-width: 992px) {
    .home-top-section {
        flex-flow: column;
        align-self: start;
    }
    .search-section {
        background-color: transparent;
        min-height: unset;
        padding: 30px 13px;
    }
    .search-input-wrapper {
        position: initial;
        background-color: transparent;
        padding: 20px 15px;
        flex-direction: column;
    }

    .search-input-wrapper>button {
        width: unset;
        margin-top: 10px;
    }

    .search-input-wrapper>input {
        width: unset;
    }

    .wrap-token{
        padding:0;
        margin-left: 0;
    }
}

/* mobile screen */
@media screen and (max-width: 740px){
    .left{
        width: 38px;
    }

    .token-infor{
        padding: 5px 5%;
    }
}
