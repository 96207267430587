.kai-footer {
    background-color: var(--nav-color);
    color: var(--white-color);
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 80%;
    margin: 0 auto;
}

.footer-icon{
    color:var(--white-color);
    margin-right: 22px;
}

.footer-icon:last-child{
    margin-right:0;
}

/* mobile screen */
@media screen and (max-width: 740px) {
    .kai-footer{
        flex-direction: column-reverse;
        padding: 15px 0px;
    }

    .footer-container {
        width:100%;
        padding:0px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}
/* end mobile screen */