.dot-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.dot-status.online {
    background: radial-gradient(#b1eca9, #068c03);
}
.dot-status.offline {
    background: radial-gradient(#f7bebe, #ff0000);
}
.network-container {
    width: 100%;
}
.network-container .container {
    margin: auto;
}

.network-container canvas {
    width: 100% !important;
}

@media screen and (max-width: 740px) {

    .network-container .container {
      padding: 30px 20px;
      width: 100%;
    }
}