.rank-tab {
    width: 35px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    line-height: 35px;
    border-radius: 50%;
    color: var(--white-color);
}

.validators-container .staking-stats .stats-container {
    display: flex;
    align-items: center;
}
.validators-container .staking-stats .stats-container .icon {
    flex: 1;
    margin: auto;
    text-align: center;
    font-weight: 600;
    min-height: 60px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.validators-container .staking-stats .stats-container .content {
    flex: 5;
}

.validators-container .staking-stats .stats-container .value {
    font-size: 25px;
    font-weight: 600;
}

.validator-address {
  font-size: 12px;
  font-weight: 600;
}
.validator-title {
    display: inline-block;
    margin-bottom: 2px;
}
.validator-name {
    margin-bottom: 2px;
}

.validator-info {
    display: inline-block;
    vertical-align: bottom;
}

.delegate-btn {
    box-shadow: var(--box-shadow1);
}

/* Staking calculator style */
.staking-calculator {
    text-align: right;
}