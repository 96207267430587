.access-wallet-container,
.access-privatekey-container,
.access-keystore-container,
.access-mnemonic-container {
    /* margin-top: 50px; */
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

/* Style for crate wallet with private key */
.access-container {
    margin-top: 50px;
}

.wrap{
    width:100%;
}

.input{
    background: var(--input-gray-bg) !important;
}

.btn-access{
    background: var(--violet-gradient);
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.button-container .submit-buttom {
    margin: 30px 20px;
}

.access-container .rs-panel-body {
    width: 700px;
}

.rs-panel-body{
    background:#555770;
    padding:24px 32px;
}

.creatOne{
    color: var(--link-color);
    font-weight: 600;

}

.title{
    color:white;
}

.access-container .title {
    font-size: 20px;
    margin-bottom: 30px;
}

.access-wallet-container .rs-panel-heading {
    color: #ffffff;
    font-size: 20px;
    background-color: #7a848b;
}

.access-wallet-container .panel-body .rs-btn {
    text-align: left;
    padding: 20px 20px;
    background: #8E90A6;
    color: #ffffff;
    position: relative;
    padding-left: 70px;
    margin-bottom: 20px;
    box-shadow: var(--box-shadow2);
}

.access-wallet-container .panel-body .rs-btn:hover{
    box-shadow: var(--box-shadow4);
    background: linear-gradient(145.51deg, #AC5DD9 7.21%, #004FC4 94.47%);
}

.access-wallet-container .panel-body .rs-btn .rs-icon {
    height: 25px;
    width: 25px;
    left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
}

.access-privatekey-container .title,
.access-keystore-container .title,
.access-mnemonic-container .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 40px;
    margin-top: 20px;
    /* color: rgb(168 62 247); */
}

.access-keystore-container .rs-uploader-text .rs-uploader-file-item-icon::before,
.access-keystore-container .rs-uploader-file-item-title,
.access-keystore-container .rs-uploader-file-item-size {
    color: white !important;
}

.access-keystore-container .rs-uploader-text .rs-uploader-file-item:hover {
    background: transparent;
}

.access-keystore-container .rs-uploader-trigger-btn {
    background: var(--orange-highlight);
    border-radius: 8px;
    color: white;
}

/* mobile screen */
@media screen and (max-width: 740px) {
    .wrap{
        padding:0px 20px;
    }
}
/* end mobile screen */

.kardia-extension-wallet-icon{
    height: 50px;
    width: 50px;
    left: 5px;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    justify-content: center;
    align-items: center;
}