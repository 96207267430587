.click-show-more {
    color: var(--link-color);
    font-weight: 600;
    cursor: pointer;
}
.failed-reason-details {
    margin-left: 5px;
    color: #fff;
    font-size: 12px;
    font-style: italic;
}

.tx-detail-container .rs-uploader-file-item {
    background: white;
    border-radius: 8px;
}
.token-logo-small {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}