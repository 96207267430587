.logBody {
    /* padding: 12px 0px; */
    border-bottom: var(--border) !important;
    /* padding-bottom:13px; */
    padding:13px;
}

.logRow{
    display:flex;
    /* border-bottom: var(--border) !important; */
    padding:13px 0px;
}

.property-title{
    flex: 1
}

.property-content{
    flex: 11;
}

.topics{
    padding-left:0;
    flex:11;
}

.topics > li + li{
margin-top:12px;
}

.num{
    padding:5px 7px;
    color:white;
    background-color: rgba(119,131,143,.1);
}

.borderNone{
    border:none !important;
}

.right{
    flex: 11;
    display:flex;
    flex-direction: column;
}

@media only screen and (max-width: 740px) {
    .body{
        padding:0
    }
     
    .property-title{
        flex:1
    }

    .property-content, .topics, .right{
        flex:3;
    }
  }