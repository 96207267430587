/* .property-title {
    font-weight: 700;
    word-break: break-all;
} */

.del-list-container,
.val-info-container {
    margin-top: 20px;
}

.del-staking-container {
    margin-top: 40px
}

.register-button {
    border: 1px solid #041fc5;
    background: white;
    color: #0b0bff;
}

.register-container {
    width: 100%;
    margin-bottom: 10px;
}

.register-form {
    margin: 20px 0;
}

.del-staking-container .label,
.register-form .label {
    margin-bottom: 5px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
}
.latest-update-validator {
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
}
.warning-note {
    font-size: 12px;
    font-style: italic;
    color: #FF8585;
}

.edit-val-button {
    color: #ffffff;
    cursor: pointer;
    margin-left: 10px;
    background: transparent;
}

.unjail-note {
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
    color: red;
}