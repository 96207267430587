.kai-number-input {
    width: 100%;
    color: #575757;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e5e5ea;
    -webkit-transition: border-color ease-in-out 0.3s;
    transition: border-color ease-in-out 0.3s;
    border-radius: 6px;
    font-size: 14px;
    padding: 12px 11px;
    line-height: 1.42857143;
    display: inline-block;
}